<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <!-- <AddInventory
              class="mr-2"
              :initFn="initDataFn"
              :optionsWarehouse="options.delivery_address"
            ></AddInventory>
            <ReduceInventory
              class="mr-2"
              :initFn="initDataFn"
              :optionsWarehouse="options.delivery_address"
            ></ReduceInventory> -->
          </div>
          <div class="operate-box-r">
            <b-form-group
              v-if="!$refs.TAdvancedSearch.visible && tableSubmit"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Tracking No."
              label-align-sm="right"
              label-size="sm"
              label-for="view_mode"
            >
              <b-form-input
                v-model="tableSubmit.tracking_code"
                type="text"
                :placeholder="$t('page.please_choose_input')"
                @keyup.enter="filter"
              ></b-form-input>
            </b-form-group>
          </div>

          <CustomTable
            v-if="tableSubmit"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :tableObj.sync="tableSubmit"
            :tableId.sync="tableData.tabId"
            :tableLoading="loading"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import apiBus from '@/common/apiBus/index';
import TAdvancedSearch from '@/components/TAdvancedSearch/Index.vue';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import _ from 'lodash';
// import SelectLoading from '@/components/Loading/Index';

export default {
  name: 'WarehouseEntrance',
  components: {
    TAdvancedSearch,
    KTPortlet,
    Advanced,
    CustomTable
    // SelectLoading
  },
  data() {
    return {
      // 高级搜索配置
      searchConfiguration: {
        status: {
          type: 'checked-all',
          text: 'Status',
          allSelected: true,
          class: 'text_color_all',
          resetVal: [200, 210],
          md: 12,
          options: [
            { label: 'Incoming', value: 200, class: 'text_grey' },
            { label: 'Received', value: 210, class: 'text_blue' }
          ]
        },

        logistic: {
          type: 'checked-all',
          text: 'Logistic',
          allSelected: true,
          class: 'text_color_all',
          resetVal: [],
          md: 12,
          options: []
        },
        creation_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Creation Date',
          limit: {
            // start: 30,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 30))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        reception_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Reception Date',
          limit: {
            // start: 30,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 30))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        supplier: {
          type: 'select',
          text: 'Supplier Name',
          placeholder: this.$t('page.please_choose'),
          options: [],
          hideLoading: true,
          input: this.changeSupplier,
          onSearch: this.onSearch
        },
        channel: {
          type: 'select',
          text: 'Channel',
          placeholder: this.$t('page.please_choose'),
          hideLoading: true,
          options: []
        },
        warehouse: {
          type: 'select',
          text: 'Warehouse',
          placeholder: this.$t('page.please_choose'),
          // selectable: 'disabled',
          options: []
        },
        purchase_id: {
          type: 'input',
          text: 'Purchase ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        cps_order_number: {
          type: 'input',
          text: 'Order ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        tracking_code: {
          type: 'input',
          text: 'Tracking No.',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        reserve_no: {
          type: 'input',
          text: 'Reserve No.',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        }
      },
      tableData: {
        fields: [
          { key: 'creation_date', label: 'Creation Date' },
          { key: 'reception_date', label: 'Reception Date' },
          { key: 'logistic', label: 'Logistic' },
          { key: 'tracking_code', label: 'Tracking Code' },
          { key: 'purchase_id', label: 'Purchase ID' },
          { key: 'supplier_name', label: 'Supplier Name' },
          { key: 'status', label: 'Status' },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'warehouse_entrance',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        status: [200, 210],
        channel: null,
        logistic: [],
        creation_date_from: null,
        creation_date_to: null,
        reception_date_from: null,
        reception_date_to: null,
        supplier: null,
        warehouse: null,
        purchase_id: null,
        cps_order_number: null,
        tracking_code: null,
        // sort: {},
        limit: 100,
        page: 1
      },
      options: {
        delivery_address: [],
        channel: []
      },
      loading: true
    };
  },

  methods: {
    itemsFn(ctx) {
      //  if (!this.CACHE.ifCache('WarehouseEntrance')) {}
      // this.tableSubmit.tracking_code = this.initialTableSubmit.tracking_code;

      this.CACHE.addCache(
        this.currentUser.uid,
        'WarehouseEntrance',
        this.tableSubmit
      );

      this.loading = true;

      return apiBus.warehouse
        .warehouseEntranceList(this.tableSubmit)
        .then(data => {
          // console.log(data);
          this.loading = false;
          this.tableSubmit.page = ctx.currentPage;
          this.tableSubmit.limit = ctx.perPage;
          this.tableData.items = this.searchTableData(data.data.data.list);

          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;

          return this.tableData.items;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    initDataFn() {
      this.$root.$emit('bv::refresh::table', this.tableData.tabId);
    },

    onSearch(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      apiBus.purchase
        .getSupplierList({
          keyword: search
        })
        .then(data => {
          vm.searchConfiguration.supplier.options =
            data.data.data.supplier_list['Other Supplier'];
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.searchConfiguration.supplier.options = [];
          loading(false);
        });
    }, 350),

    changeSupplier(e) {
      this.searchConfiguration.channel.options = [];
      this.tableSubmit.channel = null;
      if (
        this.tableSubmit.supplier != '' &&
        this.tableSubmit.supplier != null
      ) {
        this.searchConfiguration.channel.hideLoading = false;
        apiBus.purchase
          .getChannelList({ supplier: e })
          .then(data => {
            this.searchConfiguration.channel.options =
              data.data.data.channel_list;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.searchConfiguration.channel.hideLoading = true;
      }
    },
    searchTableData(data) {
      var tableData = [];
      data.forEach(key => {
        let {
          id,
          creation_date,
          reception_date,
          logistic,
          tracking_code,
          purchase_id,
          status,
          supplier_name
        } = key;

        tableData.push({
          id: id,
          creation_date: creation_date,
          reception_date: reception_date,
          logistic:
            logistic
              .replace('_', ' ')
              .charAt(0)
              .toUpperCase() + logistic.replace('_', ' ').slice(1),
          tracking_code: tracking_code,
          purchase_id: purchase_id,
          status: status,
          supplier_name: supplier_name,
          action: 'WarehouseEntrance'
        });
      });
      return tableData;
    },
    searchOptions() {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then(data => {
            console.log('getBaseConfig数据缓存vuex3');
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            // 给Order Type赋值
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    handleOptions(data) {
      var arr = [];
      data.forEach((key, i) => {
        key.index = 1;
        if (key.value) {
          arr.push(key);
        } else {
          arr.push({
            value: 'disabled_' + i,
            label: key.label,
            index: 1,
            selectable: 'disabled'
          });
          key.children.forEach(x => {
            x.index = 2;
            arr.push(x);
          });
        }
      });
      return arr;
    },
    renewTable() {
      console.log('更新');
      this.$root.$emit('bv::refresh::table', this.tableData.tabId); // 筛选强制刷新表单
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    filter() {
      this.$root.$emit('bv::refresh::table', this.tableData.tabId);
    },
    init() {
      this.searchOptions();
      if (this.CACHE.ifCache('WarehouseEntrance')) {
        console.log('读取缓存');
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'WarehouseEntrance'
        );
        Object.keys(this.searchConfiguration).forEach(key => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.searchConfiguration[key].options != 0 &&
              this.tableSubmit[key].length ==
                this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });

        this.$refs.TAdvancedSearch.visible = this.CACHE.getVisible(
          'WarehouseEntrance'
        );
      } else {
        console.log('没有读取缓存');
        this.tableSubmit = this.initialTableSubmit;
      }

      if (
        this.tableSubmit.supplier != '' &&
        this.tableSubmit.supplier != null
      ) {
        this.searchConfiguration.channel.hideLoading = false;
        apiBus.purchase
          .getChannelList({ supplier: this.tableSubmit.supplier })
          .then(data => {
            this.searchConfiguration.channel.options =
              data.data.data.channel_list;
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },

  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },
  mounted() {
    this.init();
    // 缓存
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('WarehouseEntrance', isJustShown);
      }
    });
    // 高级搜索字段
    apiBus.purchase
      .getSupplierList()
      .then(data => {
        let options = [];
        Object.keys(data.data.data.supplier_list).forEach(item => {
          options.push({
            label: item,
            value: item.replace(/ /g, '_'),
            index: 1,
            disabled: true
          });
          // console.log(item);
          data.data.data.supplier_list[item].forEach(x => {
            options.push({
              label: x.label,
              value: x.value,
              index: 2
            });
          });
        });
        this.searchConfiguration.supplier.options =
          data.data.data.supplier_list['Other Supplier'];
      })
      .catch(error => {
        console.log(error);
      });

    // 高级搜索字段
    apiBus.purchase
      .getExpressList()
      .then(data => {
        var arr = [];
        var itemArr = [];
        var defaults = [];
        data.data.data.forEach(key => {
          key.class = 'text_no';
          arr.push(key);
          itemArr.push(key.value);
          defaults.push(key.value);
        });
        this.searchConfiguration.logistic.options = arr;
        this.tableSubmit.logistic = itemArr;
        this.searchConfiguration.logistic.resetVal = itemArr;
      })
      .catch(error => {
        console.log(error);
      });
    // 高级搜索字段
    if (this.options.delivery_address.length == 0) {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then(data => {
            console.log('getBaseConfig数据缓存vuex5');
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            this.options.delivery_address = this.baseConfig.purchase_delivery_to;
            this.searchConfiguration.warehouse.options = this.options.delivery_address;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.options.delivery_address = this.baseConfig.purchase_delivery_to;
        this.searchConfiguration.warehouse.options = this.options.delivery_address;
      }
    } else {
      this.searchConfiguration.warehouse.options = this.options.delivery_address;
    }
  },
  watch: {
    'searchConfiguration.logistic.options'(n, o) {
      if (o.length == 0 && n.length > 0) {
        Object.keys(this.searchConfiguration).forEach(key => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.searchConfiguration[key].options != 0 &&
              this.tableSubmit[key].length ==
                this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });
      }
    },
    async 'tableSubmit.supplier'(n, o) {
      if (n == null && o != null) {
        const { data } = await apiBus.purchase.getSupplierList();
        this.searchConfiguration.supplier.options =
          data.data.supplier_list['Other Supplier'];
      }
    }
  }
};
</script>
<style scoped>
.filter {
  display: flex;
  align-content: center;
  justify-content: center;
}
.filter .form-group {
  margin-bottom: 0;
  width: 200px;
}
</style>
